<template>
  <div class="survey-journal">
    <div v-if="errorMsg" class="complete">
      <p>{{ errorMsg }}</p>
      <br />
      <van-button type="info" v-on:click="cancelJournal">Close</van-button>
    </div>
    <survey v-if="isSurvey" :survey="survey" />
  </div>
</template>

<script>
import * as SurveyVue from "survey-vue";
import { TaskProvider } from "@/providers";

export default {
  name: "SurveyTaskJournal",

  props: {
    task: Object
  },

  data() {
    return {
      survey: {},
      isSurvey: false,
      errorMsg: ""
    };
  },

  mounted() {
    const surveyId = this.task.description;
    if (this.isValidSurveyId(surveyId)) {
      this.isSurvey = true;
      this.loadSurvey(surveyId);
    } else {
      this.isSurvey = false;
      this.errorMsg = "Survey ID is invalid!";
    }
  },

  methods: {
    loadSurvey(surveyId) {
      this.styleSurvey();

      const surveyJSON = {
        surveyId: surveyId
      };

      this.survey = new SurveyVue.SurveyModel(surveyJSON);

      this.survey.onAfterRenderPage.add(this.addCancelButton);
      this.survey.onComplete.add(this.completeSurvey);
    },

    styleSurvey() {
      let themeColor = SurveyVue.StylesManager.ThemeColors["orange"];

      themeColor["$text-color"] = "#2c3e50";

      SurveyVue.StylesManager.applyTheme();

      SurveyVue.surveyStrings.loadingSurvey = "Your journal is loading…";
    },

    addCancelButton() {
      const btn = document.createElement("input");
      btn.type = "button";
      btn.className = "sv_cancel_btn";
      btn.value = "Cancel";
      btn.onclick = this.cancelJournal;

      const nav = document.querySelector("div.sv_nav");
      nav.appendChild(btn);
    },

    completeSurvey(result) {
      const journalButton = document.querySelector("input.sv_complete_btn");
      journalButton.value = "Journaling ...";
      journalButton.disabled = true;

      this.doJournal(JSON.stringify(result.data, null, 3));
    },

    async doJournal(response) {
      TaskProvider.doTaskJournal(this.task.id, response).then(response =>
        this.journalDone(response.data)
      );
    },

    journalDone(task) {
      window.Event.trigger("TaskJournalingCompleted", task);
    },

    cancelJournal() {
      this.$router.push({
        name: "home-page"
      });
    },

    isValidSurveyId(surveyId) {
      const uuidPattern = /^[a-f\d]{8}-(?:[a-f\d]{4}-){3}[a-f\d]{12}$/i;
      return uuidPattern.test(surveyId);
    }
  }
};
</script>

<style>
h4.sv_page_title {
  display: none;
}

.sv_body {
  text-align: left;
}

.sv_body h3 {
  padding: 15px;
}

.sv_p_root > div:not(.sv_row),
.sv_row,
.sv_nav {
  padding-left: 10px;
  padding-right: 10px;
}

.sv_complete_btn {
  float: right;
}

h5.sv_q_title .sv_q_num,
h5.sv_q_title .sv_q_num + span {
  display: none;
}

.sv_q_title {
  font-size: 1em;
}

.sv_q_description {
  font-style: italic;
  font-size: 0.9em;
  margin-bottom: 10px;
}

.sv_body textarea {
  border-radius: 8px;
  padding: 5px;
  width: 96%;
}

.sv_main .sv-action-bar {
  margin-top: 10px;
  margin-bottom: 20px;
}

.sv_main .sv-action__content {
  padding: 10px;
}

.complete {
  margin-top: 50px;
}
</style>
